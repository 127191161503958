
import { Options, Vue, Watch } from "vue-property-decorator";
import EchartComp from "@/components/echartcomp/index.vue";
import PageBlockComp from "@/components/pageblockcomp/index.vue";
import DutyCard from "./dutycard.vue";
import * as api from "@/api/duty.ts";
import { parseTime } from "@/utils";

@Options({
  name: "Duty",
  components: {
    EchartComp,
    PageBlockComp,
    DutyCard,
  },
})
export default class extends Vue {
  private value = "";
  private popupShow = false;
  private isShow = "";
  private date = new Date();
  private currnetYear = new Date().getFullYear();
  private currnetMonth: number | string = new Date().getMonth() + 1;
  private currnetDay: number | string = new Date().getDate();
  private options = {};
  private optionsLine = {};
  countOnSite = 0;
  todaycheckin: any = {
    total: 0,
    currentCount: 0,
    leaveCount: 0,
    onSite: 0,
  };
  refreshing = false;
  finished = false;
  loading = false;
  checkinlist: any = [];
  page = {
    pageSize: 10,
    pageNum: 1,
  };

  mounted() {
    this.getCheckInToday();
    this.getCheckinrate();
  }

  @Watch("value")
  onValueChanged(newValue: string, oldValue: string) {
    if (newValue === "") {
      this.refreshing = true;
      this.onRefresh();
    }
  }

  get projectId() {
    return this.$store.state.project.projectinfo.projectId;
  }

  get currentDate() {
    this.currnetMonth =
      this.currnetMonth.toString().length < 2
        ? "0" + this.currnetMonth
        : this.currnetMonth;
    this.currnetDay =
      this.currnetDay.toString().length < 2
        ? "0" + this.currnetDay
        : this.currnetDay;
    return this.currnetYear + "-" + this.currnetMonth + "-" + this.currnetDay;
  }

  get computedtoptimetoday() {
    let date = new Date(Date.parse(this.currentDate.replace(/-/g, "/")));
    return parseTime(date, "{m}月{d}日 星期{a}");
  }

  async getCheckInToday() {
    let res: any = await api.countOnSite({
      projectId: this.projectId,
      date: this.currentDate,
    });
    if (res.code !== 0) {
      this.$toast.fail(res.msg);
      return;
    }
    console.log(res.data);

    this.todaycheckin = res.data;
    this.countOnSite = res.data.onSite;

    this.options = {
      angleAxis: {
        max: this.todaycheckin.total,
        clockwise: false, // 逆时针
        show: false,
      },
      radiusAxis: {
        type: "category",
        show: true,
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      polar: {
        center: ["50%", "50%"],
        radius: [35, 50],
      },
      series: [
        {
          type: "bar",
          data: [this.todaycheckin.onSite],
          showBackground: true,
          backgroundStyle: {
            color: "#E9E9E9",
          },
          itemStyle: {
            color: "#3F7CFF",
          },
          coordinateSystem: "polar",
        },
      ],
    };
    (this as any).$refs.annularchart.setOption(this.options);
  }

  async getCheckinrate() {
    let res: any = await api.mobilecheckinrate({
      projectId: this.projectId,
      date: this.currentDate,
    });
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    let dateX = res.data.rate.map((item: any) => {
      return (
        parseInt(item.date.substr(5, 2)) +
        "." +
        parseInt(item.date.substr(8, 2))
      );
    });
    let dateY = res.data.rate.map((item: any) => {
      return item.rate.toFixed(2) * 100;
    });
    this.optionsLine = {
      tooltip: {
        trigger: "axis",
        formatter:
          '{b} <br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-image: linear-gradient(to bottom,#F1B010,#F1B010);"></span>{a}  {c} %',
      },
      legend: {
        show: false,
      },
      grid: {
        top: "5%",
        left: "5%",
        right: "5%",
        bottom: "15%",
        containLabel: false,
      },
      xAxis: {
        type: "category",
        boundaryGap: true,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          align: "center",
        },
        axisPointer: {},
        data: dateX,
      },
      yAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: "#C7CBDF",
          },
        },
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      series: [
        {
          name: "出勤率",
          type: "line",
          smooth: true,
          lineStyle: {
            color: "#F1B010",
            width: 2,
          },
          // symbol: "none",
          itemStyle: {
            color: "#F1B010",
          },
          data: dateY,
        },
      ],
    };
    (this as any).$refs.dutylinechart.setOption(this.optionsLine);
  }

  onRefresh() {
    // 清空列表数据
    this.finished = false;

    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.checkinlist = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }

      api
        .mobilecheckinlist({
          ...this.page,
          projectId: this.projectId,
          createDate: this.currentDate,
          userName: this.value,
        })
        .then((res: any) => {
          if (res.code !== 0) {
            return this.$toast.fail(res.msg);
          }
          if (res.data.records !== null) {
            this.checkinlist = [...this.checkinlist, ...res.data.records];
            this.page.pageNum++;
            this.loading = false;
            if (res.data.current >= res.data.pages) {
              this.finished = true;
            }
          }
        });
    }, 1000);
  }

  fSelectTime(ployload: string) {
    this.popupShow = true;
    this.isShow = ployload;
  }
  yearFormatter(type: string, val: string) {
    if (type === "year") {
      return val;
    }
  }
  monthFormatter(type: string, val: string) {
    if (type === "month") {
      return val;
    }
  }
  dayFormatter(type: string, val: string) {
    if (type === "day") {
      return val;
    }
  }
  fChangeTime(value: string, type: string) {
    if (type === "year") {
      this.currnetYear = new Date(value).getFullYear();
    }
    if (type === "month") {
      this.currnetMonth = new Date(value).getMonth() + 1;
    }
    if (type === "day") {
      this.currnetDay = new Date(value).getDate();
    }
    this.fCancel();
    this.getCheckinrate();
    this.getCheckInToday();
    this.checkinlist = [];
    this.page.pageNum = 1;
    this.onRefresh();
  }
  fCancel() {
    this.isShow = "";
    this.popupShow = false;
  }
  filterUsername(value: any) {
    this.refreshing = true;
    this.onRefresh();
  }
}
